@use 'assets/styles/colors';

.offeringImage {
  width: 35px;
  height: 35px;

  img {
    width: 100%;
    max-width: 35px;
    height: 100%;
    max-height: 35px;
    border-radius: 3px;
  }
}

.divider {
  margin: 8px;
}

.editableOfferingSection {
  height: 100%;
}
