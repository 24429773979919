/* Taken from app/assets/stylesheets/components/form.sass */

/* Sections Input, Addons */
@use 'assets/styles/variables' as v;
@use 'assets/styles/form' as f;
@use 'assets/styles/animation' as a;
@use 'assets/styles/colors';

$addon-width: 27px;
$addon-large-width: 30px;

.textInputWrap {
  position: relative;
  line-height: 1;
}

.disableAutofill {
  /* https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px var(--disable-autofill-color) inset !important; /* stylelint-disable-line */
  }
}

.textInput {
  width: 100%;
  border: 1px solid v.$input-border-color;
  appearance: none;
  background-color: v.$input-background-color;
  box-shadow: none;
  color: v.$input-color;
  font-size: v.$input-fontsize-base;
  font-weight: v.$input-font-weight;
  line-height: v.$input-lineheight-base;
  transition: color a.$anim-time-short a.$anim-easing;

  @include f.input-pseudoclasses;

  &::placeholder {
    color: v.$placeholder-color;
    font-weight: v.$placeholder-font-weight;
    line-height: initial;
  }

  .regular & {
    padding: v.$input-padding-base;
    border-radius: v.$input-border-radius-base;
  }

  .small & {
    padding: v.$input-padding-small;
    border-radius: v.$input-border-radius-small;
  }

  .xl & {
    padding: v.$font-size-xl;
    padding-right: v.$sp-48;
    padding-left: v.$sp-56;
    border-radius: v.$input-border-radius-xl;
    font-size: v.$font-size-20;
    font-weight: v.$input-font-weight;
    line-height: 25px;
  }

  .large & {
    padding: v.$input-padding-large;
    border-width: 2px;
    border-radius: v.$input-border-radius-large;
    font-size: v.$input-fontsize-large;
    font-weight: v.$input-font-weight-large;
    letter-spacing: v.$input-letter-spacing-large;
    line-height: v.$input-lineheight-large;

    &:hover,
    &:focus {
      box-shadow: 0 4px 20px rgba((colors.$black), 10%);
    }
  }

  &.invalid {
    border-color: v.$input-border-color-error;
  }

  &.squareCornersLeft {
    border-radius: 0 v.$input-border-radius-base v.$input-border-radius-base 0;

    &.small {
      border-radius: 0 v.$input-border-radius-small v.$input-border-radius-small
        0;
    }

    &.large {
      border-radius: 0 v.$input-border-radius-large v.$input-border-radius-large
        0;
    }
  }

  &.squareCornersRight {
    border-radius: v.$input-border-radius-base 0 0 v.$input-border-radius-base;

    &.small {
      border-radius: v.$input-border-radius-small 0 0
        v.$input-border-radius-small;
    }

    &.large {
      border-radius: v.$input-border-radius-large 0 0
        v.$input-border-radius-large;
    }
  }

  &.withShadow {
    border: 0;
    box-shadow: none;
    filter: drop-shadow(0 0 40px rgb(0 0 0 / 3%));
  }

  &.withAddon {
    &.regular {
      .begin & {
        padding-left: v.$sp-32;
      }

      .end & {
        padding-right: v.$sp-32;
      }
    }

    &.small {
      .begin & {
        padding-left: v.$sp-28;
      }

      .end & {
        padding-right: v.$sp-56;
      }
    }

    &.xl {
      .begin & {
        padding-left: v.$sp-56;
      }

      .end & {
        padding-right: v.$sp-48;
      }
    }

    &.large {
      .begin & {
        padding-left: v.$sp-40;
      }

      .end & {
        padding-right: v.$sp-40;
      }
    }
  }
}

.statusSuccess {
  &,
  &:focus,
  &:hover {
    border-color: colors.$green;
  }
}

.statusWarning {
  &,
  &:focus,
  &:hover {
    border-color: colors.$warning;
  }
}

.statusError {
  &,
  &:focus,
  &:hover {
    border-color: colors.$republic-error;
  }
}

.addon {
  position: absolute;
  z-index: v.$input-addon-z-index;
  top: 50%;
  width: $addon-width;
  color: colors.$gray-c;
  font-size: v.$input-fontsize-base;
  font-weight: 300;
  pointer-events: none;
  text-align: center;
  transform: translateY(-50%);

  &.begin {
    .regular & {
      left: 5px;
    }

    .small & {
      left: 4px;
    }

    .large & {
      left: 10px;
    }

    .xl & {
      left: 20px;
    }
  }

  &.end {
    .regular & {
      right: 5px;
    }

    .small & {
      right: 4px;
    }

    .large & {
      right: 10px;
    }
  }

  .large & {
    font-size: v.$input-fontsize-large;
  }
}

.wl.textInput {
  padding: var(--input-padding-vertical) var(--input-padding-horizontal);
  border: 1px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  background: var(--input-background);
  color: var(--input-color);
  font-size: var(--input-font-size);
  font-weight: normal;
  line-height: inherit;

  &.squareCornersLeft {
    border-radius: 0 var(--input-border-radius) var(--input-border-radius) 0;
  }

  &.squareCornersRight {
    border-radius: var(--input-border-radius) 0 0 var(--input-border-radius);
  }

  &::placeholder {
    color: var(--placeholder-color);
    font-weight: var(--placeholder-font-weight);
  }

  &:hover {
    border-color: var(--input-border-hover-color);
  }

  &:focus {
    border-color: var(--input-border-focus-color);
    box-shadow: var(--input-focus-box-shadow);
    outline: none;
  }

  &.invalid {
    border-color: var(--brand-danger);
  }

  &[disabled],
  &[disabled='disabled'],
  &[disabled='true'] {
    background-color: var(--input-background-disabled);
    color: var(--input-disabled-color);
    cursor: default;
  }

  .large & {
    border-radius: calc(var(--input-border-radius) + 2px);
    font-size: calc(var(--input-font-size) * 1.563);
  }

  .small & {
    border-radius: calc(var(--input-border-radius) - 2px);
    font-size: calc(var(--input-font-size) * 0.875);
  }

  .statusSuccess {
    &,
    &:focus,
    &:hover {
      border-color: var(--brand-success);
      box-shadow: inset 0 0 0 1px var(--brand-success);
    }
  }

  .statusWarning {
    &,
    &:focus,
    &:hover {
      border-color: var(--brand-warning);
      box-shadow: inset 0 0 0 1px var(--brand-warning);
    }
  }

  .statusError {
    &,
    &:focus,
    &:hover {
      border-color: var(--brand-error);
      box-shadow: inset 0 0 0 1px var(--brand-error);
    }
  }
}
