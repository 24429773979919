@use 'assets/styles/colors';
@use 'assets/styles/media';

.button.button.button {
  position: fixed;
  z-index: 100;
  right: 16px;
  bottom: 80px;
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  opacity: 0;
  transform: scale(0);
  transition: opacity 250ms ease-in, transform 250ms ease-in;
  visibility: hidden;

  @include media.up(lg) {
    bottom: 16px;
  }

  &.isVisible {
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms ease-out, transform 250ms ease-out;
    visibility: visible;
  }

  &:hover {
    border: none;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
    outline: none;
    transform: translateY(-3px);
    transition: transform 250ms ease-out;
  }
}

.icon {
  svg {
    color: colors.$brand-primary;
  }
}
