@use 'assets/styles/variables' as v;
@use 'assets/styles/media';
@use 'assets/styles/mixin';
@use 'assets/styles/colors';

.row {
  cursor: pointer;

  &:hover {
    background-color: colors.$gray-fa;
  }
}

.seen {
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.descriptionQuote {
  @include mixin.lineClamp(2);

  padding-left: 1brm;
  font-style: italic;

  @include media.up(md) {
    border-left: 3px solid colors.$gray-e;
  }
}

.actionBtn {
  float: right;
  white-space: nowrap;

  &.seen {
    color: colors.$gray-8;
  }
}

.dateText {
  font-size: 12px;
}
