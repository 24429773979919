@use 'assets/styles/colors';

.searchWrapper {
  &.isOpening {
    animation: slide-in-bottom 250ms ease-in both;
  }

  &.isClosing {
    animation: slide-out-bottom 250ms ease-out both;
  }

  &.isFocused {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    overflow: auto;
    width: 100%;
    height: 100vh;
    padding: 12px 16px 300px;
    background-color: colors.$white;
  }
}

.search {
  width: 100%;
}

.popularSuggestions {
  padding: 20px 0;
  filter: none;
}

.autosuggest {
  filter: none;
  overflow-y: scroll;
}

.closeButton.closeButton.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes slide-in-bottom {
  0% {
    top: 25vh;
  }

  100% {
    top: 0;
  }
}

@keyframes slide-out-bottom {
  0% {
    top: 0;
  }

  100% {
    top: 25vh;
  }
}
