@use 'assets/styles/variables' as v;
@use 'assets/styles/media';
@use 'assets/styles/colors';

@mixin largeSize {
  width: 145px;
  min-width: 145px;
  height: 145px;
}

@mixin mediumSize {
  width: 104px;
  min-width: 104px;
  height: 104px;
}

@mixin smallSize {
  width: 48px;
  min-width: 48px;
  height: 48px;
}

@mixin xSmallSize {
  width: 35px;
  min-width: 35px;
  height: 35px;
}

@mixin xxSmallSize {
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.avatar {
  position: relative;
  overflow: hidden;
  width: 104px;
  height: 104px;
  align-items: center;
  justify-content: center;
  border-radius: v.$border-radius-l;
  line-height: 1;
}

.container {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  place-content: center center;
}

.round {
  border-radius: 50%;
}

.border {
  border: 1px solid rgba((colors.$black), 10%);
}

.offeringCard {
  width: 60px;
  min-width: 60px;
  height: 60px;
}

.avatarImg.avatarImg.avatarImg.avatarImg {
  position: relative;
  display: flex;
  width: inherit;
  max-width: 100%;
  height: inherit;
  max-height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  background: colors.$white;

  &::before {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    box-shadow: none;
    content: '';
  }

  &.outline::before {
    box-shadow: inset 0 0 0 1px rgb((colors.$black), 5%);
  }
}

.avatarImg.avatarImg.avatarImg > img {
  min-width: 100%;
  min-height: 100%;
  object-fit: contain;
}

.large {
  @include largeSize;

  &Mobile {
    @include media.only(xs) {
      @include largeSize;
    }
  }
}

.medium {
  @include mediumSize;

  &Mobile {
    @include media.only(xs) {
      @include mediumSize;
    }
  }
}

.small {
  @include smallSize;

  &Mobile {
    @include media.only(xs) {
      @include smallSize;
    }
  }
}

.xSmall {
  @include xSmallSize;

  &Mobile {
    @include media.only(xs) {
      @include xSmallSize;
    }
  }
}

.xxSmall {
  @include xxSmallSize;

  &Mobile {
    @include media.only(xs) {
      @include xxSmallSize;
    }
  }
}

.badge {
  position: absolute;
  right: calc(-2% - 2px);
  bottom: calc(-2% - 2px);
  width: calc(25% + 6px);
  height: calc(25% + 6px);
}
