/* stylelint-disable declaration-no-important */
@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';
@use 'assets/styles/media';

$banner-state-change-background-grad1: #a9faff20;
$banner-state-change-background-grad2: #ffffff02;

.bannerLink {
  display: block;
  padding: v.$sp-16;
  background: linear-gradient(
    0deg,
    $banner-state-change-background-grad1 0%,
    $banner-state-change-background-grad2 100%
  );
  cursor: pointer;
}

.bannerLink,
.bannerLink:link,
.bannerLink:visited {
  color: inherit;
  text-decoration: none;
}

.actionRequiredBtn {
  margin-bottom: v.$sp-8;
  text-transform: uppercase;

  @include media.up(sm) {
    margin: 0;
  }
}

.bannerTxt {
  font-size: v.$font-size-s !important;

  @include media.up(sm) {
    font-size: v.$font-size-m !important;
  }
}

.verticalLine {
  height: 100%;
  border-left: 1px solid rgba((colors.$black), 0.3);
}
