@use 'sass:color';
@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

.label {
  display: inline-block;
  margin-bottom: v.$sp-12;
  color: colors.$gray-2;
  cursor: pointer;
  font-size: v.$font-size-base;
  font-weight: 600;
  line-height: 1.3;

  &.unclickable {
    cursor: default;
  }

  &.large {
    margin-bottom: v.$base-rhythm;
    font-size: v.$font-size-large;
  }

  &.small {
    margin-bottom: v.$sp-6;
    font-size: v.$font-size-small;
  }

  &.disabled {
    color: colors.$disabled;
    cursor: default;
  }

  &.invalid {
    color: colors.$danger;
  }
}
