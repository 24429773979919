@use 'assets/styles/colors';
@use 'assets/styles/variables' as v;
@use 'assets/styles/media';

.searchIcon {
  width: 20px;
  height: 20px;
  color: colors.$gray-40;

  &__focused {
    color: colors.$gray-70;
  }

  path {
    fill: rgba((colors.$black), 0.2);
  }
}

.crossWrapper {
  position: absolute;
  top: 50%;
  right: 11px;
  border: 0;
  background: none;
  cursor: pointer;
  transform: translate(0, -50%);

  svg {
    width: 18px;
    height: 18px;
  }

  &:hover path {
    fill: colors.$black;
    opacity: 0.3;
  }
}

.search {
  .searchIcon {
    path {
      fill: colors.$gray-6;
    }
  }

  input::placeholder {
    color: colors.$gray-6;
  }
}

.inputField.inputField.inputField.inputField {
  padding: 8px 11px 7px 46px;
  border-radius: 100px;
  background-color: colors.$gray-f2;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.search:not(.isFocused) {
  .inputField.inputField.inputField.inputField {
    &:hover {
      background-color: colors.$gray-e;
    }
  }
}

.addon.addon.addon {
  left: 11.5px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.searchEnter {
  overflow: hidden;
  width: 200px;
}

.searchEnterActive {
  opacity: 0;
}
