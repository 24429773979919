@use 'assets/styles/colors';
@use 'assets/styles/variables' as v;
@use 'assets/styles/reset_mixin' as rmx;

.footer {
  background-color: colors.$brand-darkest-blue;
  color: colors.$white;

  h5 {
    border-bottom: 1px solid rgba((colors.$white), 0.1);
  }

  ul {
    @include rmx.reset-list;

    a {
      &:hover {
        color: colors.$white;
        text-decoration: none;
      }
    }
  }

  p {
    word-break: break-word;
  }

  .socialLinks svg {
    display: inline-block;
    margin-right: 8px;
    color: rgba((colors.$white), 0.6);

    &:hover {
      color: colors.$white;
    }
  }

  .footerContent {
    border-bottom: rgba((colors.$white), 0.1) 1px solid;
  }
}

.footerSubTitle {
  color: rgba((colors.$white), 0.5);
}

// @TODO: https://linear.app/republic/issue/USMKT-431/switch-back-the-crypto-column-in-the-footer-on-r-client-and-monolith
.column {
  &.categoriesColumn {
    padding-right: 0;
  }
}
