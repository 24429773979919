@use 'assets/styles/colors';
@use 'assets/styles/variables';

.searchWrapper {
  position: relative;
  overflow: hidden;
  height: 40px;
}

.popularSuggestions {
  width: 400px;
  animation: fade-in-bottom 100ms ease-in both;

  &.isUnmounted {
    animation: fade-out-bottom 100ms ease-out both;
  }
}

.dropdown {
  z-index: 10001;
  overflow: hidden;
}

.search {
  z-index: 10000;
  top: 0;
  left: 0;
  overflow: hidden;
  margin-bottom: 4px;
}

.fakeSearch {
  width: 200px;
  margin-right: 8px;
  margin-left: 8px;
}

.inputFieldDashboard.inputFieldDashboard.inputFieldDashboard {
  width: 0;

  &.isFocused {
    width: 400px;
    animation: width-increase-dashboard 100ms ease-out both;
    background-color: colors.$white;
  }

  &.isUnmounted {
    animation: width-decrease-dashboard 100ms ease-out both;
  }
}

.inputFieldNoDashboard.inputFieldNoDashboard.inputFieldNoDashboard {
  width: 0;

  &.isFocused {
    width: 400px;
    animation: width-increase-no-dashboard 100ms ease-out both;
    background-color: colors.$white;
  }

  &.isUnmounted {
    animation: width-decrease-no-dashboard 100ms ease-out both;
  }
}

.inputFieldDesktop.inputFieldDesktop.inputFieldDesktop {
  width: 200px;

  &.isFocused {
    width: 400px;
    animation: width-increase-desktop 100ms ease-out both;
    background-color: colors.$white;
  }

  &.isUnmounted {
    animation: width-decrease-desktop 100ms ease-out both;
  }
}

.addonDashboard.addonDashboard.addonDashboard {
  &.isFocused {
    animation: addon-appear-dashboard 100ms ease-out both;
  }

  &.isUnmounted {
    animation: addon-disappear-dashboard 100ms ease-out both;
  }
}

.addonNoDashboard.addonNoDashboard.addonNoDashboard {
  &.isFocused {
    animation: addon-appear-no-dashboard 100ms ease-out both;
  }

  &.isUnmounted {
    animation: addon-disappear-no-dashboard 100ms ease-out both;
  }
}

.autosuggest {
  max-width: 400px;
  animation: fade-in-bottom 100ms ease-in both;

  &.isUnmounted {
    animation: fade-out-bottom 100ms ease-out both;
    animation-delay: 50ms;
  }
}

@keyframes fade-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-bottom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes addon-appear-dashboard {
  0% {
    transform: translate3d(200px, -50%, 0);
  }

  100% {
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes addon-disappear-dashboard {
  0% {
    transform: translate3d(0, -50%, 0);
  }

  100% {
    transform: translate3d(200px, -50%, 0);
  }
}

@keyframes addon-appear-no-dashboard {
  0% {
    transform: translate3d(125px, -50%, 0);
  }

  100% {
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes addon-disappear-no-dashboard {
  0% {
    transform: translate3d(0, -50%, 0);
  }

  100% {
    transform: translate3d(125px, -50%, 0);
  }
}

@keyframes width-increase-dashboard {
  0% {
    width: 0;
    transform: translateX(200px);
  }

  100% {
    width: 400px;
    transform: translateX(0);
  }
}

@keyframes width-decrease-dashboard {
  0% {
    width: 400px;
    transform: translateX(0);
  }

  100% {
    width: 0;
    transform: translateX(200px);
  }
}

@keyframes width-increase-no-dashboard {
  0% {
    width: 0;
    transform: translateX(125px);
  }

  100% {
    width: 400px;
    transform: translateX(0);
  }
}

@keyframes width-decrease-no-dashboard {
  0% {
    width: 400px;
    transform: translateX(0);
  }

  100% {
    width: 0;
    transform: translateX(125px);
  }
}

@keyframes width-increase-desktop {
  0% {
    width: 200px;
  }

  100% {
    width: 400px;
  }
}

@keyframes width-decrease-desktop {
  0% {
    width: 400px;
  }

  100% {
    width: 200px;
  }
}

.searchBar {
  display: flex;
  width: 200px;
  height: 40px;
  align-items: center;
  padding-left: 16px;
  border-radius: 100px;
  background-color: colors.$gray-f2;
}

.searchIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: colors.$gray-40;

  path {
    fill: colors.$gray-b;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.searchPlaceholder {
  margin-top: 2px;
  color: colors.$gray-b;
  font-size: 16px;
  font-weight: 300;
  line-height: initial;
}
