@use './media';

$MEDIA_SIZES: (lg, md, sm, xs);
$MEDIA_SIZES_REVERSE: (xs, sm, md, lg);

@mixin mediaSwitcher($size) {
  @if $size == lg {
    @content;
  } @else {
    @include media.down($size) {
      @content;
    }
  }
}

@function nameSelector($name, $size) {
  @if $size == lg {
    @return #{$name};
  } @else {
    @return #{$name}-#{$size};
  }
}

@mixin declare($name) {
  @each $size in $MEDIA_SIZES {
    @include mediaSwitcher($size) {
      .#{nameSelector($name, $size)} {
        @content;
      }
    }
  }
}

@mixin generateStyleVariations($possible-values, $style-name) {
  @each $size in $MEDIA_SIZES {
      @each $value in $possible-values {
          @include mediaSwitcher($size) {
              .#{nameSelector(#{$style-name}-#{$value}, $size)} {
                  #{$style-name}: $value;
                  @content;
              }
          }
      }
    }
}

@mixin generateStyleWithCssVar($name) {
  @each $size in $MEDIA_SIZES {
    @include mediaSwitcher($size) {
      .#{nameSelector($name, $size)} {
        @if $size == lg {
          #{$name}: var(--#{$name});
        } @else {
          #{$name}: var(--#{$name}-#{$size});
        }
        @content;
      }
    }
  }
}
