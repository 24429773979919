@use 'assets/styles/variables' as vars;
@use 'assets/styles/colors';

li.menuItem {
  line-height: 1.5;

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.menuItemInner {
  display: block;
  padding: vars.$sp-10 vars.$sp-16 vars.$sp-10;
  margin: 0;
  white-space: nowrap;

  &:hover {
    background-color: rgba((colors.$black), 0.03);
  }
}
