@use 'sass:color';
@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

@mixin themes {
  color: colors.$brand-primary;
  text-decoration: none;

  &:hover {
    color: color.adjust(colors.$brand-primary, $lightness: 10%);
    text-decoration: underline;
  }

  &:active {
    color: color.adjust(colors.$brand-primary, $lightness: 10%);
  }

  &:focus {
    color: color.adjust(colors.$brand-primary, $lightness: 10%);
  }

  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
  }

  &.muted {
    color: colors.$gray-7;

    &:hover {
      color: colors.$brand-primary;
    }
  }

  &.lite,
  &.liteBlue {
    font-weight: 300;
  }

  &.lite {
    color: colors.$gray-6;

    &:hover {
      color: colors.$gray-5;
    }
  }

  &.unstyled,
  &.wl.unstyled {
    color: inherit;
    text-decoration: inherit;

    &:hover,
    &:active,
    &:focus {
      color: colors.$brand-primary;
      outline: none;
      text-decoration: inherit;
    }
  }

  &.underline,
  &.wl.underline {
    border-bottom: 1px solid currentColor;

    &:hover,
    &:active,
    &:focus {
      outline: none;
      text-decoration: inherit;
    }
  }

  &.noUnderline {
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      outline: none;
      text-decoration: none;
    }
  }

  &.white {
    color: colors.$white;
  }

  &.dark {
    color: colors.$gray-2;

    &:hover,
    &:focus {
      color: colors.$brand-primary;
      text-decoration: none;
    }

    &:active {
      background-color: colors.$gray-fa;
    }
  }

  &.inherit,
  &.wl.inherit {
    color: inherit;

    &:hover,
    &:active,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
  }

  &.inheritedUnderline,
  &.wl.inheritedUnderline {
    border-bottom: 1px solid currentColor;
    color: inherit;

    &:hover,
    &:active,
    &:focus {
      outline: none;
      text-decoration: inherit;
    }
  }

  &.wl {
    color: var(--link-color);

    &:hover {
      color: var(--link-hover-color);
      text-decoration: underline;
    }

    &:active {
      color: var(--link-hover-color);
    }

    &:focus {
      color: var(--link-hover-color);
    }

    &.dark {
      color: colors.$gray-7;

      &:hover,
      &:focus {
        color: var(--link-color);
        text-decoration: none;
      }

      &:active {
        background-color: colors.$gray-e;
      }
    }
  }
}
