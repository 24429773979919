@use 'assets/styles/colors';
@use 'assets/styles/media';
@use 'assets/styles/variables' as v;

.disclaimerBox {
  color: rgb(255 255 255 / 50%);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.2;

  @include media.only(xs) {
    &Mobile {
      font-size: v.$font-size-s;
    }
  }
}

.mainDisclaimerBox {
  composes: disclaimerBox;
  line-height: 1.4;
}
