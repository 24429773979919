@use 'assets/styles/variables' as v;
@use 'assets/styles/colors' as c;
@use 'assets/styles/mixin' as m;
@use 'assets/styles/media';

.main {
  border-radius: 1brm;
  background-color: c.$white;
  filter: drop-shadow(0 0 40px rgb(0 0 0 / 3%));

  &.isClosing {
    animation: fade-out-bottom 100ms ease-out both;
    animation-delay: 50ms;
  }
}

.avatar {
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: v.$border-radius-l;
}

.offeringsList {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr;
}

.offeringName {
  margin-bottom: 2px;
  font-size: 16px;
  letter-spacing: -0.01em;
  line-height: 24px;

  @include m.lineClamp(1);
}

.offeringDescription {
  @include m.lineClamp(2);
}

.offering {
  padding: 8px 20px 10px 8px;
  border-radius: 8px;
  margin: 0 -8px;
  transition: background-color 100ms ease-in;

  &:hover {
    background-color: c.$gray-f7;
    transition: background-color 100ms ease-out;
  }
}

.sectionName {
  color: c.$gray-8;
  font-size: 14px;
  line-height: 18px;
}

.link {
  color: c.$black;
  font-weight: 600;

  &:hover {
    color: c.$gray-6;
  }
}

.noResult {
  position: relative;
  border-radius: v.$border-radius-xl;
  background-color: c.$gray-f2;
}

.noResultText {
  margin-bottom: 16px;
}

.noResultDivider {
  margin: 28px 0;
}

.noResultIcon {
  position: absolute;
  top: 16px;
  left: 20px;
  display: block;
}

.trendingInfo {
  padding: 0;
  vertical-align: -2px;
}

.tag.tag.tag {
  padding: 3px 8px;
  border-radius: v.$border-radius-m;
  color: rgba((c.$black), 0.6);
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  vertical-align: top;

  &:hover {
    color: c.$black;
  }
}
