@use '../links.module.scss';

.link {
  @include links.themes;
}

.rightArrow,
.rightArrowHover {
  &::after {
    display: inline-block;
    border-width: 4px 0 4px 6px;
    border-style: solid;
    border-color: transparent;
    border-left-color: currentColor;
    margin-left: 0.3em;
    content: ' ';
    vertical-align: middle;
  }
}

.rightArrowHover {
  &::after {
    opacity: 0;
  }

  &:hover::after {
    opacity: 1;
  }
}
