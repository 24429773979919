@use 'assets/styles/colors';
@use 'assets/styles/media';

.relativeContainer {
  position: relative;
  z-index: 1;
}

.container {
  position: absolute;
  transform: translateX(-4px) translateY(-4px);

  @include media.up(sm) {
    transform: translateX(-25px) translateY(15px);
  }

  @include media.up(md) {
    transform: translateX(-30px) translateY(15px);
  }
}

.compactContainer {
  transform: translateX(-2px) translateY(-2px);
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: colors.$brand-primary;
  box-shadow: 0 0 0 2px colors.$white;

  @include media.up(sm) {
    width: 18px;
    height: 18px;
    border: 5px solid colors.$white;
    border-radius: 50%;
    background-color: colors.$brand-primary;
  }
}

.compactDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: colors.$brand-primary;
  box-shadow: 0 0 0 2px colors.$white;

  @include media.up(sm) {
    border: none;
  }
}
