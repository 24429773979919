
@mixin reset-list-item{
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@mixin reset-list{
  padding: 0;
  margin: 0;
  list-style: none;

  & > li{
    @include reset-list-item;
  }
}

@mixin reset-button{
  border: none;
  appearance: none;
  background: transparent;
  box-shadow: none;
  text-align: left;

  &:focus,
  &:active{
    outline: none;
  }
}
