@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

.itemTitle {
  font-size: v.$font-size-l;
  line-height: 1.5;
}

.nestedAccordianList {
  padding: 0;
}

.nestedMenuItem {
  padding-left: 2brm;
}

.login,
.signUp {
  color: colors.$gray-7;
  font-size: v.$font-size-20;

  &:hover {
    color: colors.$brand-primary;
  }
}

.featuredInvestorsLogo {
  img {
    height: 16px;
  }
}

.notificationsCount {
  position: absolute;
  right: 10px;
  min-width: 45px;
  padding: 5px 10px;
  border-radius: 18px;
  background-color: #ffdbd9;
  color: colors.$danger;
  font-weight: 500;
  text-align: center;
}

.new.new.new {
  height: 24px;
  padding: 5px 5px 3px 6px;
  border-radius: v.$border-radius-large;
  margin-left: 0.5brm;
  color: colors.$white;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: -0.01em;
  line-height: 16px;
}

.userMenu {
  flex: 1;
}
