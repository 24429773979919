@use 'assets/styles/media_style_generator' as generator;

$align-self: auto, flex-start, flex-end, baseline, stretch, center;

@include generator.generateStyleVariations($align-self, 'align-self');
@include generator.generateStyleWithCssVar('flex-shrink');
@include generator.generateStyleWithCssVar('flex-basis');

@include generator.generateStyleWithCssVar('flex-grow') {
  flex-basis: 0;
}
