@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';
@use 'assets/styles/media';

.messageContainer {
  min-height: 44px;
  background-color: colors.$warning;
}

.bannerText {
  font-size: v.$font-size-s;

  @include media.up(md) {
    font-size: v.$font-size-m;
  }
}

.warningIcon {
  color: colors.$white;
}

.hiddenXs {
  display: none;

  @include media.up(md) {
    display: inline;
  }
}
