@use "sass:color";
@use './variables' as v;
@use './colors';

@mixin input-pseudoclasses {
  &:hover {
    border-color: v.$input-border-color-hover;
  }

  &:focus {
    border: 1px solid colors.$brand-primary;
    box-shadow: v.$input-focus-outline;
    outline: none;
  }

  &[disabled] {
    background-color: color.adjust(colors.$gray-7, $lightness: 50%);
    color: colors.$silver;
    cursor: default;

    &:hover {
      border-color: v.$input-border-color;
    }
  }
}
