.svg {
  display: inline;
  fill: currentColor;
}

.svg_1x {
  height: 1em;
  vertical-align: -0.125em;
}

.svg_no_vertical_alignment {
  vertical-align: 0;
}

.svg_legacy_vertical_alignment {
  vertical-align: -3px;
}
