@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';
@use 'assets/styles/media';

.actionWrapper {
  position: relative;
}

.daysLeftMessage {
  display: none;
  margin-bottom: v.$sp-8;

  @include media.up(sm) {
    display: inline-block;
    flex: 1;
    margin-bottom: 0;
  }
}

.action.action.action {
  padding: 10px 24px;
  /* stylelint-disable-next-line declaration-no-important */
  background-color: colors.$white !important;
  line-height: 24px;

  @include media.up(sm) {
    position: absolute;
    top: 50%;
    right: v.$sp-8;
    transform: translateY(-50%);
  }
}
