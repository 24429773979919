@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes appearRotate {
  from {
    transform: rotate(0deg) scale(0.4);
  }

  to {
    transform: rotate(360deg) scale(1);
  }
}

.spinnerWrapper {
  color: colors.$republic-blue;

  &.gray {
    color: colors.$gray-7;
  }

  &.inverted {
    color: colors.$white;
  }

  &.medium {
    font-size: 1.5brm;
  }

  &.tiny {
    font-size: 0.9brm;
  }

  &.large {
    font-size: 2brm;
  }
}

.fullscreen {
  position: fixed;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba((colors.$white), 0.75);
  inset: 0;

  &.opaque {
    background: rgba((colors.$white), 1);
  }

  &.inverted {
    background-color: rgba((colors.$black), 0.75);

    &.opaque {
      background-color: rgba((colors.$black), 1);
    }
  }
}

.solidBackground {
  background: colors.$white;
}

.centered {
  text-align: center;
}

.rotate {
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  margin: 0 0 -0.23em;
  animation: rotate 0.35s 0.35s linear infinite, appearRotate 0.35s ease-in;
}

@keyframes dash {
  from {
    stroke-dashoffset: 2400;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.lineDraw,
.fillAnimate {
  &.tiny {
    width: 50px;
  }

  &.small {
    width: 100px;
  }

  &.regular {
    width: 150px;
  }
}

.lineDraw path {
  animation: dash 4s linear normal infinite;
  stroke-dasharray: 1200;
  stroke-dashoffset: 500;
}

@keyframes draw {
  0% {
    fill-opacity: 0;
    stroke-dashoffset: 1650;
  }

  30% {
    fill-opacity: 0;
    stroke-dashoffset: 0;
  }

  60% {
    fill-opacity: 1;
    stroke-dashoffset: 0;
    stroke-opacity: 0;
  }

  80% {
    fill-opacity: 1;
    stroke-dashoffset: 0;
    stroke-opacity: 0;
  }

  100% {
    fill-opacity: 0;
    stroke-dashoffset: 0;
    stroke-opacity: 0;
  }
}

.fillAnimate {
  path {
    animation: draw 5s linear normal infinite;
    stroke-dasharray: 1250;
    stroke-dashoffset: 1250;
  }
}
