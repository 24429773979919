@use 'assets/styles/animation' as a;
@use 'assets/styles/variables' as v;

.image {
  transition: opacity v.$transition-time a.$anim-easing,
    transform v.$transition-time a.$anim-easing;
}

.image.fullWidth {
  width: 100%;
}

.wrapper {
  display: contents;
  overflow: hidden;
  line-height: 0;
}

.image.hoverEffectZoom:hover,
.hoverHandle:hover .image.hoverEffectZoom {
  opacity: 0.8;
  transform: scale(1.02);
}
