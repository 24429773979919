@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';
@use 'assets/styles/mixin';

$search-result-photo-size: 36px;

.badge {
  padding: 2px 7px;
  border: 1px solid rgba(#818695, 0.3);
  border-radius: 8px;
  background: #fff;
  color: #818695;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 22px;

  &::first-letter {
    text-transform: uppercase;
  }

  &.live {
    border-color: rgba(colors.$search-offering-live, 0.3);
    color: colors.$search-offering-live;
  }

  &.comingSoon {
    border-color: rgba(colors.$search-offering-coming-soon, 0.2);
    color: colors.$search-offering-coming-soon;
  }
}

.autosuggest {
  padding: v.$sp-8;
  border: 1px solid rgb(0 0 0 / 5%);
  border-radius: 12px;
  box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
}

.suggestion {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  margin-right: -8px;
  margin-left: -8px;
  color: #000;
  gap: 12px;
  text-decoration: none;

  &:first-child {
    margin-top: 10px;
  }

  &:hover,
  &:focus {
    background-color: colors.$gray-f7;
    color: colors.$black;
    text-decoration: none;

    .badge {
      border-color: colors.$gray-f7;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.imageContainer {
  width: $search-result-photo-size;
  min-width: $search-result-photo-size;
  height: $search-result-photo-size;
}

.imageTag {
  border-radius: inherit;
  background-color: rgba((colors.$black), 0.04);
}

.imagePhoto {
  overflow: hidden;
  border-radius: 8px;

  &::before {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    content: '';
  }
}

.title {
  line-height: 24px;

  @include mixin.textEllipsis;
}
