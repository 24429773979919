@use 'assets/styles/variables' as v;
@use 'assets/styles/colors' as c;

.main {
  display: inline-block;
  font-size: v.$font-size-s;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 22px;
  text-transform: capitalize;
  transition: 0.2s ease-in-out;
  transition-property: color, border-color, background-color, opacity;
  vertical-align: top;

  &.base {
    padding: v.$sp-2 v.$sp-8 v.$sp-4;
    border-radius: v.$border-radius;

    &.big {
      max-height: 40px;
      padding: 8px 16px;
      border-radius: 12px;
      font-size: v.$font-size-m;
      font-weight: 500;
      line-height: 24px;
    }
  }

  &.interest {
    display: flex;
    cursor: pointer;

    figure {
      display: inline-flex;
      align-items: center;
    }

    &.medium {
      padding: 7px 16px 12px;
      border-radius: 16px;
      font-size: v.$font-size-l;
      line-height: 25px;
    }

    &.big {
      max-height: 68px;
      padding: 18px 20px 22px;
      border-radius: 24px;
      font-size: v.$font-size-xl;
      letter-spacing: -0.7px;
      line-height: 28px;
    }

    .icon {
      margin-right: 0.75brm;
      line-height: 0;
    }
  }

  &.catalogTag {
    display: inline-flex;
    min-height: 36px;
    align-items: center;
    padding: v.$sp-8 v.$sp-16 v.$sp-10 v.$sp-12;
    border-radius: 100px;
    background-color: c.$gray-f2;
    color: c.$gray-80;
    line-height: 18px;

    .icon {
      line-height: 0;
    }

    .tagText {
      margin-right: 12px;
      margin-left: 8px;
    }

    svg {
      color: c.$gray-9;
    }

    &:hover {
      background-color: #ececec;

      .close {
        cursor: pointer;

        path {
          opacity: 0.3;
        }
      }
    }

    &.canClose {
      position: relative;
      padding-right: 9px;

      .close {
        align-self: flex-end;
        line-height: 0;
      }
    }
  }
}
