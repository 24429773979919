.inputGroup {
  width: 100%;

  &.withMargin {
    margin-bottom: 2brm;
  }
}

.hint {
  max-width: 38.5brm;
  margin-top: 0.5brm;

  &.thin {
    font-weight: 300;
  }
}

.wrapperInline {
  display: flex;

  & .label {
    margin-right: 2brm;
  }
}
