@use 'assets/styles/variables' as v;
@use 'assets/styles/media_style_generator' as msg;
@use 'assets/styles/colors';

@each $size in msg.$MEDIA_SIZES {
  @include msg.mediaSwitcher($size) {
    @each $name,
      $val
        in (
          0: 0,
          0_5: 0.5rem,
          1: 1rem,
          1_5: 1.5rem,
          2: 2rem,
          2_5: 2.5rem,
          3: 3rem,
          3_5: 3.5rem,
          4: 4rem
        )
    {
      .#{msg.nameSelector(margin-top-#{$name}, $size)} {
        margin-top: $val;
      }
      .#{msg.nameSelector(margin-bottom-#{$name}, $size)} {
        margin-bottom: $val;
      }
      .#{msg.nameSelector(margin-#{$name}, $size)} {
        margin-top: $val;
        margin-bottom: $val;
      }
    }

    @each $name, $val in ('default': 100%, 'small': 6rem, 'extra-small': 2.5rem)
    {
      .#{msg.nameSelector(size-#{$name}, $size)} {
        width: $val;
      }
    }
  }
}

.divider {
  width: 100%;
  border: 0;
  border-top-width: 1px;
  border-top-style: solid;
  margin-right: auto;
  margin-left: auto;

  &.color-default {
    border-top-color: colors.$gray-e;
  }

  &.color-gray-6 {
    border-top-color: colors.$gray-6;
  }

  &.color-gray10 {
    border-top-color: colors.$gray-e0;
  }

  &.color-gray20 {
    border-top-color: colors.$gray-20;
  }

  &.align-left {
    margin-left: 0;
  }
}

.divider[data-hint] {
  position: relative;
  display: inline-block;
  overflow: visible;
  width: 100%;
  margin-top: v.$sp-16;
  margin-bottom: v.$sp-16;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0 v.$sp-8;
    background: colors.$white;
    color: colors.$gray-a;
    content: attr(data-hint);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    transform: translateX(-50%) translateY(-53%);
    white-space: nowrap;
  }
}
