@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';
@use 'assets/styles/media';

.bannerContainer {
  padding: 0.5brm 1brm;
  background-color: colors.$color-gray-d;
  font-size: v.$font-size-s;

  @include media.up(md) {
    font-size: v.$font-size-m;
  }

  @include media.up(lg) {
    font-size: v.$font-size-l;
  }
}

.fireEmoji {
  margin-right: 4px;
  font-size: 23px;
}

.noDecoration,
.noDecoration:hover {
  /* stylelint-disable-next-line declaration-no-important */
  color: inherit !important;
  text-decoration: none;
}

.dealsLink.dealsLink.dealsLink,
.dealsLink:hover {
  border-bottom: 1px solid colors.$silver;
  /* stylelint-disable-next-line declaration-no-important */
  color: inherit !important;
  letter-spacing: -0.015em;
}

.avatar {
  margin-right: 6px;
  box-shadow: 4px 4px 7px colors.$gray-e0;
}

.avatarStack {
  margin-right: 6px;
  margin-left: -25px;
}

.button.button.button {
  padding: 10px 24px;
  /* stylelint-disable-next-line declaration-no-important */
  font-size: v.$font-size-m !important;
  line-height: 24px;
}

.closeIcon {
  margin-left: 16px;
  color: colors.$gray-6;
  cursor: pointer;
  vertical-align: middle;

  &:hover {
    color: inherit;
  }
}

.closeIconDisabled {
  cursor: default;

  &:hover {
    color: colors.$gray-6;
  }
}

.closingText {
  letter-spacing: -0.015em;
}
