@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

.message {
  font-size: v.$font-size-m;
}

.error {
  color: colors.$danger;
}

.success {
  color: colors.$brand-success;
}

.normal {
  color: colors.$gray-7;
  font-size: v.$font-size-s;
}
