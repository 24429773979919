@use 'assets/styles/variables' as vars;
@use 'assets/styles/colors';

$translate-y: -10px;
$animation-time: 200ms;

@mixin useBorderArrow {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 9px;
  border-style: solid;
  margin: auto;
  content: '';
}

.overlay {
  z-index: vars.$tooltip-z-index;
  padding-top: 0.5brm;
}

.overlayUnderModal {
  z-index: vars.$tooltip-under-modal-z-index;
}

.overlayContent {
  max-height: max-content;
  border-radius: 6px;
  background-color: colors.$white;
  box-shadow: 0 1px 2px rgb((colors.$black), 20%),
    0 -1px 0 rgb((colors.$black), 2%);
}

.arrow {
  top: 0;
  left: 10px;
  margin-top: -9px;
}

.arrowContent {
  width: 18px;
  height: 18px;
  pointer-events: none;

  &::before {
    @include useBorderArrow;

    top: -1px;
    border-color: transparent transparent rgb((colors.$black), 3%) transparent;
  }

  &::after {
    @include useBorderArrow;

    border-color: transparent transparent colors.$white transparent;
  }
}

.overlayEnter {
  opacity: 0;
  transform: translateY($translate-y);
}

.overlayEnterActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity $animation-time, transform $animation-time;
}

.overlayExit {
  opacity: 1;
}

.overlayExitActive {
  opacity: 0;
  transform: translateY($translate-y);
  transition: opacity $animation-time, transform $animation-time;
}
