@use 'assets/styles/colors';

.overlay {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba((colors.$black), 0.25);
  opacity: 0.99;

  &.isActive {
    display: block;
  }
}
