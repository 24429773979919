@use 'assets/styles/colors';

.main {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  &.withGrayLightBg {
    background-color: colors.$dropzone-label;
  }
}

.content {
  min-height: 80vh;
  flex-grow: 1;
}
