@use 'assets/styles/variables' as v;
@use 'assets/styles/media';

.olElement {
  padding-left: v.$sp-40;
  margin-top: v.$sp-16;
  list-style: decimal;

  li {
    margin-bottom: v.$sp-8;

    ol {
      list-style: upper-roman;

      li ol {
        list-style: upper-alpha;
      }
    }
  }
}

.main {
  margin-top: v.$sp-16;

  & > li {
    position: relative;

    &:not(:last-child) {
      margin-bottom: v.$sp-8;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background-repeat: no-repeat;
      content: '';
    }
  }

  &.disc {
    // TODO Change spacing variables once RC-391 is done
    padding-left: 1.5em;

    & > li {
      list-style: disc;
    }
  }

  &.circle {
    // TODO Change spacing variables once RC-391 is done
    padding-left: 1.5em;

    & > li {
      list-style: circle;
    }
  }
}

.main.large {
  // TODO Change font-weight variables
  font-size: v.$font-size-l;
  font-weight: 300;

  @include media.up(sm) {
    font-size: v.$font-size-xxl;
  }
}
