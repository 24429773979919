@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

.target {
  position: relative;

  &::after {
    position: absolute;
    top: -16px;
    left: -16px;
    width: calc(100% + 32px);
    height: calc(100% + 32px);
    content: '';
    pointer-events: none;
  }
}

.tooltip {
  /* theme = light */
  --tooltip-text-color: #{colors.$gray-60};
  --tooltip-border-color: #{colors.$gray-e0};
  --tooltip-background-color: #{colors.$white};

  z-index: v.$tooltip-z-index;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  &.themeDark {
    --tooltip-text-color: #{colors.$white};
    --tooltip-border-color: #{colors.$gray-80};
    --tooltip-background-color: #{colors.$gray-80};
  }

  &.themeWarn {
    --tooltip-text-color: #{colors.$republic-error};
    --tooltip-border-color: #{colors.$republic-error};
    --tooltip-background-color: #{colors.$white};
  }

  &.themeLight {
    --tooltip-text-color: #{colors.$black-80};
    --tooltip-border-color: #{colors.$gray-e0};
    --tooltip-background-color: #{colors.$white};
  }

  &.appearActive,
  &.appearDone {
    opacity: 1;
  }

  &.appearDone {
    pointer-events: auto;
  }
}

.content {
  position: relative;
  max-width: 250px;
  padding: v.$sp-8 v.$sp-12;
  border: solid 1px colors.$white;
  border: solid 1px var(--tooltip-border-color);
  border-radius: v.$border-radius;
  background: colors.$white;
  background: var(--tooltip-background-color);
  box-shadow: 0 0 15px rgba((colors.$black), 10%);
  color: colors.$black-80;
  color: var(--tooltip-text-color);
  cursor: pointer;
  font-size: 14px;
  line-height: 1.4;
  transition: transform 0.3s, opacity 0.3s;
  word-break: normal;

  &.sizeTiny {
    max-width: 200px;
  }

  &.sizeBig {
    max-width: 310px;
  }

  &.sizeLarge {
    max-width: 370px;
  }
}

.tooltip[data-popper-placement^='top'] .content {
  transform: translate(0, 30px);
}

.tooltip[data-popper-placement^='bottom'] .content {
  transform: translate(0, -30px);
}

.tooltip[data-popper-placement^='left'] .content {
  transform: translate(30px);
}

.tooltip[data-popper-placement^='right'] .content {
  transform: translate(-30px);
}

.tooltip.appearActive[data-popper-placement] .content,
.tooltip.appearDone[data-popper-placement] .content {
  transform: translate(0);
}

.arrow,
.arrow::before,
.arrow::after {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  background-color: colors.$white;
  background-color: var(--tooltip-background-color);
  color: colors.$gray-e0;
  color: var(--tooltip-border-color);
}

.arrow {
  visibility: hidden;
}

.arrow::before,
.arrow::after {
  content: '';
  transform: rotate(45deg);
  visibility: visible;
}

.arrow::before {
  background-color: currentColor;
}

.arrow::after {
  background-color: inherit;
}

.tooltip[data-popper-placement^='top'] .arrow,
.tooltip[data-popper-placement^='bottom'] .arrow {
  left: 50%;
  transform: translate(-50%, 0);
}

.tooltip[data-popper-placement^='left'] .arrow,
.tooltip[data-popper-placement^='right'] .arrow {
  top: 50%;
  transform: translate(0, -50%);
}

.tooltip[data-popper-placement^='top'] .arrow {
  bottom: -5px;

  &::after {
    top: -1px;
  }
}

.tooltip[data-popper-placement^='bottom'] .arrow {
  top: -5px;

  &::after {
    top: 1px;
  }
}

.tooltip[data-popper-placement^='left'] .arrow {
  right: -5px;

  &::after {
    left: -1px;
  }
}

.tooltip[data-popper-placement^='right'] .arrow {
  left: -5px;

  &::after {
    left: 1px;
  }
}
