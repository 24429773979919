@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';
@use 'assets/styles/media';

.actionWrapper {
  position: relative;
}

.infoIcon {
  display: inline;
  margin-left: 6px;
}

.action {
  /* stylelint-disable-next-line declaration-no-important */
  background-color: colors.$white !important;

  @include media.up(sm) {
    position: absolute;
    top: 50%;
    right: v.$sp-8;
    transform: translateY(-50%);
  }
}
