@use 'assets/styles/colors';

.dropdownActions {
  display: flex;
  border-top: 1px solid colors.$gray-e0;
}

.dropdownAction {
  flex: 1;
  color: colors.$gray-60;

  &:hover {
    color: colors.$brand-primary;
  }
}

.bordered {
  border-right: 1px solid colors.$gray-e0;
}

.displayContents.displayContents {
  display: contents;
}

.listContainer {
  width: 352px;
  max-height: 448px;
  overflow-y: auto;
}
